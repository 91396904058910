/* THIS FILE IS GENERATED - DO NOT EDIT IT MANUALLY */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string | Date; output: string | Date };
  JSON: { input: unknown; output: unknown };
  StrOrBool: { input: string | boolean; output: string | boolean };
};

export type AuthM2MApplication = {
  clientId: Scalars['ID']['output'];
  clientSecret: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Connection = {
  Id: Scalars['ID']['output'];
  configuration: Scalars['JSON']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platform: Scalars['Int']['output'];
};

export type CreateAuthM2MApplicationPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<AuthM2MApplication>;
  status: OperationStatus;
};

export type CreateOrganizationPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<Organization>;
  status: OperationStatus;
};

export type CreateSavedSearchInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  query: Scalars['JSON']['input'];
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateSavedSearchPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<SavedSearch>;
  status: OperationStatus;
};

export type CreateUserInput = {
  auth0UserId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  lastLoginTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  tenantId: Scalars['ID']['input'];
  userType?: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type CreateUserPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<UserProfile>;
  status: OperationStatus;
};

export type DeleteAuthM2MApplicationPayload = {
  errors: Array<Scalars['String']['output']>;
  status: OperationStatus;
};

export type DeleteOrganizationPayload = {
  errors: Array<Scalars['String']['output']>;
  status: OperationStatus;
  successful: Array<Scalars['ID']['output']>;
};

export type DeleteSavedSearchPayload = {
  deletedRecord?: Maybe<Scalars['ID']['output']>;
  errors: Array<Scalars['String']['output']>;
  status: OperationStatus;
};

export type DeleteTeamMemberPayload = {
  errors: Array<Scalars['String']['output']>;
  status: OperationStatus;
  successfulDeletes: Array<Scalars['ID']['output']>;
  teamId: Scalars['ID']['output'];
};

export type DeleteUserPayload = {
  deletedRecords: Array<Scalars['ID']['output']>;
  errors: Array<Scalars['String']['output']>;
  status: OperationStatus;
};

export type FederatedJoinConfiguration = {
  childConnection: PlatformConnectionConfiguration;
  childConnectionId: Scalars['Int']['output'];
  childRecordType: RecordEnum;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  joinPredicate: Scalars['JSON']['output'];
  parentConnection: PlatformConnectionConfiguration;
  parentConnectionId: Scalars['Int']['output'];
  parentRecordType: RecordEnum;
  relationship: Scalars['String']['output'];
  tenantId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InviteTeamMemberInput = {
  email: Scalars['String']['input'];
  role?: TeamMembershipRole;
};

export type InviteTeamMemberPayload = {
  errors: Array<Scalars['String']['output']>;
  successfulInvites: Array<Scalars['String']['output']>;
  teamId: Scalars['ID']['output'];
};

export type Mutation = {
  createFederatedJoinConfiguration: FederatedJoinConfiguration;
  /** Create a new platform connection. connection_configuration should have stringified json blob. */
  createPlatformConnectionConfiguration?: Maybe<PlatformConnectionConfiguration>;
  createSavedSearch: CreateSavedSearchPayload;
  deleteFederatedJoinConfiguration: FederatedJoinConfiguration;
  deletePlatformConnectionConfiguration: Scalars['Int']['output'];
  deleteSavedSearch: DeleteSavedSearchPayload;
  organization: OrganizationMutation;
  updateFederatedJoinConfiguration: FederatedJoinConfiguration;
  /** Update an existing platform connection. Provide schema_mapping value as empty dictionary i.e. {}, if you want to delete existing schema_mapping. */
  updatePlatformConnectionConfiguration?: Maybe<PlatformConnectionConfiguration>;
  updateSavedSearch: UpdateSavedSearchPayload;
  user: UserMutation;
};

export type MutationCreateFederatedJoinConfigurationArgs = {
  childConnectionId: Scalars['Int']['input'];
  childRecordType: RecordEnum;
  isActive: Scalars['Boolean']['input'];
  joinPredicate: Scalars['JSON']['input'];
  parentConnectionId: Scalars['Int']['input'];
  parentRecordType: RecordEnum;
  relationship: Scalars['String']['input'];
};

export type MutationCreatePlatformConnectionConfigurationArgs = {
  connectionConfigurationV2?: InputMaybe<Scalars['JSON']['input']>;
  introspectionData?: InputMaybe<Scalars['JSON']['input']>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  platformId: Scalars['Int']['input'];
  platformName: Scalars['String']['input'];
  schemaMapping?: InputMaybe<Scalars['JSON']['input']>;
  teamId: Scalars['ID']['input'];
};

export type MutationCreateSavedSearchArgs = {
  createSavedSearchInput: CreateSavedSearchInput;
};

export type MutationDeleteFederatedJoinConfigurationArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeletePlatformConnectionConfigurationArgs = {
  id: Scalars['Int']['input'];
  teamId: Scalars['ID']['input'];
};

export type MutationDeleteSavedSearchArgs = {
  id: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateFederatedJoinConfigurationArgs = {
  childConnectionId: Scalars['Int']['input'];
  childRecordType: RecordEnum;
  id: Scalars['Int']['input'];
  isActive: Scalars['Boolean']['input'];
  joinPredicate: Scalars['JSON']['input'];
  parentConnectionId: Scalars['Int']['input'];
  parentRecordType: RecordEnum;
  relationship: Scalars['String']['input'];
};

export type MutationUpdatePlatformConnectionConfigurationArgs = {
  connectionConfigurationV2?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['Int']['input'];
  introspectionData?: InputMaybe<Scalars['JSON']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['Int']['input'];
  schemaMapping?: InputMaybe<Scalars['JSON']['input']>;
  teamId: Scalars['ID']['input'];
};

export type MutationUpdateSavedSearchArgs = {
  updateSavedSearchInput: UpdateSavedSearchInput;
};

export enum OperationStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export type Organization = {
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Get all users in the organization */
  members: Array<OrganizationMember>;
  metadata: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  status: OrganizationStatus;
  /** Get all the teams in the organization */
  teams: Array<Team>;
};

export type OrganizationInput = {
  metadata: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
};

export type OrganizationMember = {
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  /** Get teams user is part of */
  teams: Array<Team>;
  userId: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
};

export type OrganizationMutation = {
  /**
   * Create an organization by specifying organization name, settings.
   * A team is created by default with same name as organization and default setting.
   * Unset create_team flag to not create team.
   * Set team_input to change team name and team settings.
   */
  create: CreateOrganizationPayload;
  /** Delete Organization. Support user can delete any organization */
  deleteById: DeleteOrganizationPayload;
  /** Set Organization/s active. Support user can inactivate any organization */
  setActive: UpdateOrganizationStatusPayload;
  /** Set Organization/s inactive. Support user can inactivate any organization */
  setInactive: UpdateOrganizationStatusPayload;
  team: TeamMutation;
  /** Update organization name or settings */
  updateSettings: UpdateOrganizationSettingsPayload;
};

export type OrganizationMutationCreateArgs = {
  createTeam?: Scalars['Boolean']['input'];
  organizationInput: OrganizationInput;
  teamInput?: InputMaybe<TeamInput>;
};

export type OrganizationMutationDeleteByIdArgs = {
  orgId: Array<Scalars['ID']['input']>;
};

export type OrganizationMutationSetActiveArgs = {
  orgId: Array<Scalars['ID']['input']>;
};

export type OrganizationMutationSetInactiveArgs = {
  orgId: Array<Scalars['ID']['input']>;
};

export type OrganizationMutationUpdateSettingsArgs = {
  orgId: Scalars['ID']['input'];
  organizationInput: OrganizationInput;
};

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type Platform = {
  active: Scalars['Boolean']['output'];
  alias: PlatformConnectionFieldProperties;
  category: PlatformCategory;
  defaultSchemaMapping?: Maybe<Scalars['JSON']['output']>;
  generatedFields?: Maybe<Array<PlatformConnectionGeneratedFieldProperties>>;
  iconFileName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  loginFields: Array<PlatformConnectionFieldProperties>;
  name: Scalars['String']['output'];
  offForFeature?: Maybe<Scalars['String']['output']>;
  onForFeature?: Maybe<Scalars['String']['output']>;
  optionalParams?: Maybe<Array<PlatformConnectionFieldProperties>>;
  snippets?: Maybe<Array<PlatformConnectionSnippetProperties>>;
};

export enum PlatformCategory {
  CloudInfrastructureAndDataLakes = 'CLOUD_INFRASTRUCTURE_AND_DATA_LAKES',
  EmailSecurityAndCommunications = 'EMAIL_SECURITY_AND_COMMUNICATIONS',
  Endpoint = 'ENDPOINT',
  IdentityAndHr = 'IDENTITY_AND_HR',
  ItServiceManagement = 'IT_SERVICE_MANAGEMENT',
  MobileDeviceManagement = 'MOBILE_DEVICE_MANAGEMENT',
  Network = 'NETWORK',
  SiemAndLogManagement = 'SIEM_AND_LOG_MANAGEMENT',
  ThreatIntelligenceAndEnrichment = 'THREAT_INTELLIGENCE_AND_ENRICHMENT',
}

export type PlatformConnectionConfiguration = {
  connectionConfiguration?: Maybe<Scalars['JSON']['output']>;
  connectionConfigurationV2?: Maybe<Scalars['JSON']['output']>;
  /** Get federated join configurations that refer to this connection */
  federatedJoinConfigurations: Array<FederatedJoinConfiguration>;
  id: Scalars['Int']['output'];
  introspectionData?: Maybe<Scalars['JSON']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platformId: Scalars['Int']['output'];
  platformName: Scalars['String']['output'];
  /** Get schema mappings for the platform connection */
  schemaMapping?: Maybe<Scalars['JSON']['output']>;
  teamId: Scalars['Int']['output'];
  teamName: Scalars['String']['output'];
};

export type PlatformConnectionFieldProperties = {
  default?: Maybe<Scalars['StrOrBool']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  loginMethod?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  values?: Maybe<Scalars['JSON']['output']>;
};

export type PlatformConnectionGeneratedFieldProperties = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['StrOrBool']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type PlatformConnectionSnippetProperties = {
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  helpUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  template: Scalars['JSON']['output'];
};

export type PutTeamMemberPayload = {
  errors: Array<Scalars['String']['output']>;
  successfulUpserts: Array<Scalars['ID']['output']>;
  teamId: Scalars['ID']['output'];
};

export type Query = {
  /**
   * Fetch Auth M2M Application using client ID.
   * Only team admins will be allowed to make this query.
   */
  authM2mAppUsingId: QueryAuthM2MApplication;
  /** Retrieves a list of configured federated joins that the current user has access to. */
  federatedJoinConfiguration: Array<FederatedJoinConfiguration>;
  /** Query for a saved search using saved search id. */
  getSavedSearchById: QuerySavedSearchByIdPayload;
  /** Query for saved searches shared with a team using team id. Only team admins will have permission to execute this. */
  getSavedSearchesForTeam: QuerySavedSearchesPayload;
  /** Query for saved searches for a given user using user_id. */
  getSavedSearchesForUser: QuerySavedSearchesPayload;
  /**
   * Search for organization using subscription_id alongwith its related objects like users, teams, connections, etc.
   * Only Auth0 (or aws_marketplace lambda) will be able to access the organization using subscription_id.
   * Used in the AWS Marketplace Lambda to check for the existence of an organization with the same subscription_id before creating a new one.
   */
  organizationBySubscriptionId: QueryOrganizationPayload;
  /**
   * Search for organizations by ID alongwith its related objects like users, teams, connections, etc.
   * Note that query will take longer when asking multiple organizations and their related objects.
   * Only support user will be able to access all the organizations. A user will be able to access only the parent organization.
   */
  organizationsById: QueryOrganizationPayload;
  /**
   * Search for organizations by names alongwith its related objects like users, teams, connections, etc.
   * Note that query will take longer when asking multiple organizations and their related objects.
   * Only support user will be able to access all the organizations. A user will be able to access only the parent organization.
   */
  organizationsByName: QueryOrganizationPayload;
  /** Retrieves a list of configured platform connections that the current user has access to. */
  platformConnectionConfiguration: Array<PlatformConnectionConfiguration>;
  /** Retrieves a list of all platforms, and the fields required to establish connections to those platforms, and the descriptions for those fields. */
  platformConnectionField: Array<Platform>;
  /**
   * Search for users using email address alongwith its related objects like teams to which user belongs and role of user in each of the teams.
   * Specify limit (for the number of records to be fetched, default is 100) and offset (for the number of records to be skipped, default is 0).
   * Only the team admin will be able to access all users of a team.
   */
  usersByEmail: QueryUsersPayload;
  /**
   * Search for users using user_id alongwith its related objects like teams to which user belongs and role of user in each of the teams.
   * Specify limit (for the number of records to be fetched, default is 100) and offset (for the number of records to be skipped, default is 0).
   * Only the team admin will be able to access all users of a team.
   */
  usersById: QueryUsersPayload;
  /**
   * Search for users a team using team_id.
   * Specify limit (for the number of records to be fetched, default is 100) and offset (for the number of records to be skipped, default is 0).
   * Only the team admin will be able to access all users of a team.
   */
  usersOfTeam: QueryUsersPayload;
  /**
   * Search for users of a tenant using tenant_id.
   * Specify limit (for the number of records to be fetched, default is 100) and offset (for the number of records to be skipped, default is 0).
   * Only the tenant admin will be able to access all users of a tenant.
   */
  usersOfTenant: QueryUsersPayload;
};

export type QueryAuthM2mAppUsingIdArgs = {
  m2mAppClientId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type QueryFederatedJoinConfigurationArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type QueryGetSavedSearchByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSavedSearchesForTeamArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  teamId: Scalars['ID']['input'];
};

export type QueryGetSavedSearchesForUserArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type QueryOrganizationBySubscriptionIdArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type QueryOrganizationsByIdArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  orgId: Array<Scalars['ID']['input']>;
};

export type QueryOrganizationsByNameArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  orgName: Array<Scalars['String']['input']>;
};

export type QueryPlatformConnectionConfigurationArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  teamId: Scalars['Int']['input'];
};

export type QueryPlatformConnectionFieldArgs = {
  limit?: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
};

export type QueryUsersByEmailArgs = {
  email: Array<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersByIdArgs = {
  id: Array<Scalars['ID']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersOfTeamArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  teamId: Scalars['ID']['input'];
};

export type QueryUsersOfTenantArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  tenantId: Scalars['ID']['input'];
};

export type QueryAuthM2MApplication = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<AuthM2MApplication>;
};

export type QueryOrganizationPayload = {
  errors: Array<Scalars['String']['output']>;
  records: Array<Organization>;
};

export type QuerySavedSearchByIdPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<SavedSearch>;
};

export type QuerySavedSearchesPayload = {
  errors: Array<Scalars['String']['output']>;
  records: Array<SavedSearch>;
};

export type QueryUsersPayload = {
  errors: Array<Scalars['String']['output']>;
  records: Array<UserProfile>;
};

export enum RecordEnum {
  ClassAccountChange = 'CLASS_ACCOUNT_CHANGE',
  ClassApiActivity = 'CLASS_API_ACTIVITY',
  ClassApplicationLifecycle = 'CLASS_APPLICATION_LIFECYCLE',
  ClassAuthentication = 'CLASS_AUTHENTICATION',
  ClassAuthorizeSession = 'CLASS_AUTHORIZE_SESSION',
  ClassComplianceFinding = 'CLASS_COMPLIANCE_FINDING',
  ClassConfigState = 'CLASS_CONFIG_STATE',
  ClassDatastoreActivity = 'CLASS_DATASTORE_ACTIVITY',
  ClassDataSecurityFinding = 'CLASS_DATA_SECURITY_FINDING',
  ClassDetectionFinding = 'CLASS_DETECTION_FINDING',
  ClassDeviceConfigStateChange = 'CLASS_DEVICE_CONFIG_STATE_CHANGE',
  ClassDhcpActivity = 'CLASS_DHCP_ACTIVITY',
  ClassDnsActivity = 'CLASS_DNS_ACTIVITY',
  ClassEmailActivity = 'CLASS_EMAIL_ACTIVITY',
  ClassEmailDeliveryActivity = 'CLASS_EMAIL_DELIVERY_ACTIVITY',
  ClassEmailFileActivity = 'CLASS_EMAIL_FILE_ACTIVITY',
  ClassEmailUrlActivity = 'CLASS_EMAIL_URL_ACTIVITY',
  ClassEntityManagement = 'CLASS_ENTITY_MANAGEMENT',
  ClassFileActivity = 'CLASS_FILE_ACTIVITY',
  ClassFileHosting = 'CLASS_FILE_HOSTING',
  ClassFtpActivity = 'CLASS_FTP_ACTIVITY',
  ClassGroupManagement = 'CLASS_GROUP_MANAGEMENT',
  ClassHttpActivity = 'CLASS_HTTP_ACTIVITY',
  ClassIncidentFinding = 'CLASS_INCIDENT_FINDING',
  ClassInventoryInfo = 'CLASS_INVENTORY_INFO',
  ClassKernelActivity = 'CLASS_KERNEL_ACTIVITY',
  ClassKernelExtension = 'CLASS_KERNEL_EXTENSION',
  ClassMemoryActivity = 'CLASS_MEMORY_ACTIVITY',
  ClassModuleActivity = 'CLASS_MODULE_ACTIVITY',
  ClassNetworkActivity = 'CLASS_NETWORK_ACTIVITY',
  ClassNetworkFileActivity = 'CLASS_NETWORK_FILE_ACTIVITY',
  ClassNtpActivity = 'CLASS_NTP_ACTIVITY',
  ClassPatchState = 'CLASS_PATCH_STATE',
  ClassPrefetchInfo = 'CLASS_PREFETCH_INFO',
  ClassProcessActivity = 'CLASS_PROCESS_ACTIVITY',
  ClassRdpActivity = 'CLASS_RDP_ACTIVITY',
  ClassRegistryKeyActivity = 'CLASS_REGISTRY_KEY_ACTIVITY',
  ClassRegistryKeyInfo = 'CLASS_REGISTRY_KEY_INFO',
  ClassRegistryValueActivity = 'CLASS_REGISTRY_VALUE_ACTIVITY',
  ClassRegistryValueInfo = 'CLASS_REGISTRY_VALUE_INFO',
  ClassResourceActivity = 'CLASS_RESOURCE_ACTIVITY',
  ClassScanActivity = 'CLASS_SCAN_ACTIVITY',
  ClassScheduledJobActivity = 'CLASS_SCHEDULED_JOB_ACTIVITY',
  ClassSecurityFinding = 'CLASS_SECURITY_FINDING',
  ClassSmbActivity = 'CLASS_SMB_ACTIVITY',
  ClassSshActivity = 'CLASS_SSH_ACTIVITY',
  ClassUserAccess = 'CLASS_USER_ACCESS',
  ClassUserInventory = 'CLASS_USER_INVENTORY',
  ClassVulnerabilityFinding = 'CLASS_VULNERABILITY_FINDING',
  ClassWebResourcesActivity = 'CLASS_WEB_RESOURCES_ACTIVITY',
  ClassWebResourceAccessActivity = 'CLASS_WEB_RESOURCE_ACCESS_ACTIVITY',
  ObjectAccount = 'OBJECT_ACCOUNT',
  ObjectActor = 'OBJECT_ACTOR',
  ObjectAffectedCode = 'OBJECT_AFFECTED_CODE',
  ObjectAffectedPackage = 'OBJECT_AFFECTED_PACKAGE',
  ObjectAgent = 'OBJECT_AGENT',
  ObjectAnalytic = 'OBJECT_ANALYTIC',
  ObjectApi = 'OBJECT_API',
  ObjectAttack = 'OBJECT_ATTACK',
  ObjectAuthorization = 'OBJECT_AUTHORIZATION',
  ObjectCertificate = 'OBJECT_CERTIFICATE',
  ObjectCisBenchmark = 'OBJECT_CIS_BENCHMARK',
  ObjectCisBenchmarkResult = 'OBJECT_CIS_BENCHMARK_RESULT',
  ObjectCisControl = 'OBJECT_CIS_CONTROL',
  ObjectCisCsc = 'OBJECT_CIS_CSC',
  ObjectCloud = 'OBJECT_CLOUD',
  ObjectCompliance = 'OBJECT_COMPLIANCE',
  ObjectContainer = 'OBJECT_CONTAINER',
  ObjectCve = 'OBJECT_CVE',
  ObjectCvss = 'OBJECT_CVSS',
  ObjectCwe = 'OBJECT_CWE',
  ObjectDatabase = 'OBJECT_DATABASE',
  ObjectDatabucket = 'OBJECT_DATABUCKET',
  ObjectDataSecurity = 'OBJECT_DATA_SECURITY',
  ObjectDceRpc = 'OBJECT_DCE_RPC',
  ObjectDevice = 'OBJECT_DEVICE',
  ObjectDeviceHwInfo = 'OBJECT_DEVICE_HW_INFO',
  ObjectDigitalSignature = 'OBJECT_DIGITAL_SIGNATURE',
  ObjectDisplay = 'OBJECT_DISPLAY',
  ObjectDnsAnswer = 'OBJECT_DNS_ANSWER',
  ObjectDnsQuery = 'OBJECT_DNS_QUERY',
  ObjectDomainInfo = 'OBJECT_DOMAIN_INFO',
  ObjectDomainIntelligence = 'OBJECT_DOMAIN_INTELLIGENCE',
  ObjectEmail = 'OBJECT_EMAIL',
  ObjectEmailAuth = 'OBJECT_EMAIL_AUTH',
  ObjectEndpoint = 'OBJECT_ENDPOINT',
  ObjectEndpointConnection = 'OBJECT_ENDPOINT_CONNECTION',
  ObjectEnrichment = 'OBJECT_ENRICHMENT',
  ObjectEpss = 'OBJECT_EPSS',
  ObjectEvidences = 'OBJECT_EVIDENCES',
  ObjectExtension = 'OBJECT_EXTENSION',
  ObjectFeature = 'OBJECT_FEATURE',
  ObjectFile = 'OBJECT_FILE',
  ObjectFileIntelligence = 'OBJECT_FILE_INTELLIGENCE',
  ObjectFinding = 'OBJECT_FINDING',
  ObjectFindingInfo = 'OBJECT_FINDING_INFO',
  ObjectFingerprint = 'OBJECT_FINGERPRINT',
  ObjectFirewallRule = 'OBJECT_FIREWALL_RULE',
  ObjectGroup = 'OBJECT_GROUP',
  ObjectHassh = 'OBJECT_HASSH',
  ObjectHttpCookie = 'OBJECT_HTTP_COOKIE',
  ObjectHttpHeader = 'OBJECT_HTTP_HEADER',
  ObjectHttpRequest = 'OBJECT_HTTP_REQUEST',
  ObjectHttpResponse = 'OBJECT_HTTP_RESPONSE',
  ObjectIdp = 'OBJECT_IDP',
  ObjectImage = 'OBJECT_IMAGE',
  ObjectIpIntelligence = 'OBJECT_IP_INTELLIGENCE',
  ObjectJob = 'OBJECT_JOB',
  ObjectKbArticle = 'OBJECT_KB_ARTICLE',
  ObjectKernel = 'OBJECT_KERNEL',
  ObjectKernelDriver = 'OBJECT_KERNEL_DRIVER',
  ObjectKeyboardInfo = 'OBJECT_KEYBOARD_INFO',
  ObjectKillChainPhase = 'OBJECT_KILL_CHAIN_PHASE',
  ObjectLdapPerson = 'OBJECT_LDAP_PERSON',
  ObjectLoadBalancer = 'OBJECT_LOAD_BALANCER',
  ObjectLocation = 'OBJECT_LOCATION',
  ObjectLogger = 'OBJECT_LOGGER',
  ObjectMalware = 'OBJECT_MALWARE',
  ObjectManagedEntity = 'OBJECT_MANAGED_ENTITY',
  ObjectMetadata = 'OBJECT_METADATA',
  ObjectMetric = 'OBJECT_METRIC',
  ObjectModule = 'OBJECT_MODULE',
  ObjectNetworkConnectionInfo = 'OBJECT_NETWORK_CONNECTION_INFO',
  ObjectNetworkEndpoint = 'OBJECT_NETWORK_ENDPOINT',
  ObjectNetworkInterface = 'OBJECT_NETWORK_INTERFACE',
  ObjectNetworkProxy = 'OBJECT_NETWORK_PROXY',
  ObjectNetworkTraffic = 'OBJECT_NETWORK_TRAFFIC',
  ObjectObject = 'OBJECT_OBJECT',
  ObjectObservable = 'OBJECT_OBSERVABLE',
  ObjectOrganization = 'OBJECT_ORGANIZATION',
  ObjectOs = 'OBJECT_OS',
  ObjectPackage = 'OBJECT_PACKAGE',
  ObjectPeripheralDevice = 'OBJECT_PERIPHERAL_DEVICE',
  ObjectPolicy = 'OBJECT_POLICY',
  ObjectProcess = 'OBJECT_PROCESS',
  ObjectProduct = 'OBJECT_PRODUCT',
  ObjectQueryInfo = 'OBJECT_QUERY_INFO',
  ObjectRegistryKey = 'OBJECT_REGISTRY_KEY',
  ObjectRegistryValue = 'OBJECT_REGISTRY_VALUE',
  ObjectRegKey = 'OBJECT_REG_KEY',
  ObjectRegValue = 'OBJECT_REG_VALUE',
  ObjectRelatedEvent = 'OBJECT_RELATED_EVENT',
  ObjectRelatedFindings = 'OBJECT_RELATED_FINDINGS',
  ObjectRemediation = 'OBJECT_REMEDIATION',
  ObjectReputation = 'OBJECT_REPUTATION',
  ObjectRequest = 'OBJECT_REQUEST',
  ObjectResource = 'OBJECT_RESOURCE',
  ObjectResourceDetails = 'OBJECT_RESOURCE_DETAILS',
  ObjectResponse = 'OBJECT_RESPONSE',
  ObjectRpcInterface = 'OBJECT_RPC_INTERFACE',
  ObjectRule = 'OBJECT_RULE',
  ObjectSan = 'OBJECT_SAN',
  ObjectScan = 'OBJECT_SCAN',
  ObjectSecurityState = 'OBJECT_SECURITY_STATE',
  ObjectService = 'OBJECT_SERVICE',
  ObjectSession = 'OBJECT_SESSION',
  ObjectSubTechnique = 'OBJECT_SUB_TECHNIQUE',
  ObjectTable = 'OBJECT_TABLE',
  ObjectTactic = 'OBJECT_TACTIC',
  ObjectTechnique = 'OBJECT_TECHNIQUE',
  ObjectThreatIntelligence = 'OBJECT_THREAT_INTELLIGENCE',
  ObjectTls = 'OBJECT_TLS',
  ObjectTlsExtension = 'OBJECT_TLS_EXTENSION',
  ObjectUrl = 'OBJECT_URL',
  ObjectUrlIntelligence = 'OBJECT_URL_INTELLIGENCE',
  ObjectUser = 'OBJECT_USER',
  ObjectVulnerability = 'OBJECT_VULNERABILITY',
  ObjectWebResource = 'OBJECT_WEB_RESOURCE',
  ObjectWinResource = 'OBJECT_WIN_RESOURCE',
}

export type RotateM2MApplicationSecretPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<AuthM2MApplication>;
  status: OperationStatus;
};

export type SavedSearch = {
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  query: Scalars['JSON']['output'];
  teamId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type Team = {
  /** Get all connections in the organization */
  connections: Array<Connection>;
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Get all team members name, id and role */
  members: Array<TeamMember>;
  metadata: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  role: TeamMembershipRole;
  /** Get all saved searches in the team */
  savedSearches: Array<SavedSearch>;
  status: TeamStatus;
};

export type TeamInput = {
  metadata: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
};

export type TeamMember = {
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  lastLoginTime?: Maybe<Scalars['DateTime']['output']>;
  role: TeamMembershipRole;
  userId: Scalars['ID']['output'];
  userMetadata?: Maybe<Scalars['JSON']['output']>;
  userName: Scalars['String']['output'];
};

export type TeamMemberInput = {
  role?: TeamMembershipRole;
  userId: Scalars['ID']['input'];
};

export type TeamMemberMutation = {
  /** Add one or more tenant user to a team. Default role is USER. */
  invite: InviteTeamMemberPayload;
  /** Add one or more tenant user to a team. Default role is USER. */
  put: PutTeamMemberPayload;
  /** Remove one or more tenant user from a team. */
  remove: DeleteTeamMemberPayload;
};

export type TeamMemberMutationInviteArgs = {
  members: Array<InviteTeamMemberInput>;
  teamId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
};

export type TeamMemberMutationPutArgs = {
  members: Array<TeamMemberInput>;
  teamId: Scalars['ID']['input'];
};

export type TeamMemberMutationRemoveArgs = {
  teamId: Scalars['ID']['input'];
  userId: Array<Scalars['ID']['input']>;
};

export enum TeamMembershipRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export type TeamMutation = {
  /** Create Auth M2M application for the team, needed for Splunk App */
  createAuthM2mApplication: CreateAuthM2MApplicationPayload;
  /** Delete Auth M2M application using clientId */
  deleteAuthM2mApplication: DeleteAuthM2MApplicationPayload;
  member: TeamMemberMutation;
  /** Rotate client_secret for the Auth M2M application using clientId */
  rotateAuthM2mApplicationSecret: RotateM2MApplicationSecretPayload;
  /** Update team name and/or team settings. */
  update: UpdateTeamSettingsPayload;
};

export type TeamMutationCreateAuthM2mApplicationArgs = {
  teamId: Scalars['ID']['input'];
};

export type TeamMutationDeleteAuthM2mApplicationArgs = {
  authM2mClientId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type TeamMutationRotateAuthM2mApplicationSecretArgs = {
  authM2mClientId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type TeamMutationUpdateArgs = {
  teamId: Scalars['ID']['input'];
  teamInput: TeamInput;
};

export enum TeamStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type UpdateOrganizationSettingsPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<Organization>;
  status: OperationStatus;
};

export type UpdateOrganizationStatusPayload = {
  errors: Array<Scalars['String']['output']>;
  status: OperationStatus;
  successfulUpdates: Array<Scalars['ID']['output']>;
};

export type UpdateSavedSearchInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  query: Scalars['JSON']['input'];
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateSavedSearchPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<SavedSearch>;
  status: OperationStatus;
};

export type UpdateTeamSettingsPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<Team>;
  status: OperationStatus;
};

export type UpdateUserInput = {
  auth0UserId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  lastLoginTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  userType?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserPayload = {
  errors: Array<Scalars['String']['output']>;
  record?: Maybe<UserProfile>;
  status: OperationStatus;
};

export type UserMutation = {
  /** Create a user by specifying username, email, full_name, tenant_id, etc. */
  createUser: CreateUserPayload;
  /** Delete User. Only tenant admin can delete a user. */
  deleteUserById: DeleteUserPayload;
  /** Update an existing user. Only user and tenant admin can update a user profile. */
  updateUser: UpdateUserPayload;
};

export type UserMutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type UserMutationDeleteUserByIdArgs = {
  tenantId: Scalars['ID']['input'];
  userId: Array<Scalars['ID']['input']>;
};

export type UserMutationUpdateUserArgs = {
  tenantId: Scalars['ID']['input'];
  updateUserInput: UpdateUserInput;
  userId: Scalars['ID']['input'];
};

export type UserProfile = {
  auth0UserId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastLoginTime?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** Get all teams which user belongs to */
  teams: Array<Team>;
  tenantId: Scalars['ID']['output'];
  userType: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};
